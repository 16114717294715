<div class="header-component-bar">
  <button
    mat-stroked-button
    class="cciAppButton"
    (click)="openCCIWebApp()"
    id="header-component-cciapp-button"
    >CCI App</button
  >
  <mat-select
    class="header-select"
    [(ngModel)]="defaultBU"
    appearance="fill"
    (selectionChange)="onBusinessUnitChange($event.value)"
    id="header-component-select-bu"
    required>
    <mat-option
      *ngFor="let businessUnit of userBusinessUnits"
      [value]="businessUnit"
      id="header-component-bu-option-{{ businessUnitsMap.get(businessUnit) }}"
      >{{ businessUnitsMap.get(businessUnit) }}
    </mat-option>
  </mat-select>

  <button
    mat-stroked-button
    class="logoutButton"
    (click)="logout()"
    id="header-component-logout-button"
    >Logout</button
  >
</div>
