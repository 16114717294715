import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
} from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import * as _ from 'lodash';
import { MatSelect } from '@angular/material/select';

@Component({
  selector: 'aa-filter-options',
  templateUrl: './filter-options.component.html',
  styleUrls: ['./filter-options.component.scss'],
})
export class FilterOptionsComponent implements OnInit {
  // , AfterViewInit

  @Output() selectedFilterList = new EventEmitter<any>();

  @Input() required = false;

  @Input() dropDownOptions: any[] = [];

  @Input() placeholder = '';

  selectedValue: Array<string> = [];
  searchStr = '';
  selectedAll = false;

  @ViewChild(MatSelect) select!: MatSelect;

  ngOnInit(): void {
    this.searchStr = '';
  }

  // ngAfterViewInit(): void {
  // no longer in angular
  // https://github.com/angular/components/issues/23625#issuecomment-931448386
  // this.select.overlayDir.positions = [
  //   {
  //     originX: 'center',
  //     originY: 'bottom',
  //     overlayX: 'center',
  //     overlayY: 'top'
  //   }
  // ];
  // }

  onSelectAll(reset: boolean): void {
    if (!reset) {
      this.selectedAll = !this.selectedAll;
      this.selectedValue = Object.assign([], this.dropDownOptions);
      this.selectChangeHandler();
    } else {
      this.selectedValue = [];
      this.selectedAll = false;
      this.selectChangeHandler();
      this.searchStr = '';
    }
  }

  addDataToList(data: any): void {
    const index = this.selectedValue.indexOf(data, 0);
    if (index > -1) {
      this.selectedValue.splice(index, 1);
      this.selectChangeHandler();
      this.selectedAll = false;
    } else {
      this.selectedValue.push(data);
      this.selectedAll =
        this.selectedValue.length === this.dropDownOptions.length
          ? true
          : false;
      this.selectChangeHandler();
    }
  }

  toggleSelected(value: any): void {
    this.selectedValue.push(value);
  }

  selectChangeHandler(): void {
    const value = {
      field: this.placeholder,
      filterDataList: this.selectedValue,
    };
    this.selectedFilterList.emit(value);
  }

  drop(event: CdkDragDrop<string[]>): void {
    moveItemInArray(
      this.dropDownOptions,
      event.previousIndex,
      event.currentIndex,
    );
  }
}
