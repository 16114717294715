import * as fromUserSession from './user-session.reducer';
import {
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
  ActionReducer,
  MetaReducer,
} from '@ngrx/store';
import { storageSync } from '@larscom/ngrx-store-storagesync';

export interface State {
  user: fromUserSession.UserState;
}

export const reducers: ActionReducerMap<State> = {
  user: fromUserSession.userStateReducer,
};

export function storageSyncReducer(
  reducer: ActionReducer<any>,
): ActionReducer<any> {
  return storageSync<State>({
    features: [{ stateKey: 'user' }],
    storage: window.sessionStorage,
  })(reducer);
}

export const metaReducers: Array<MetaReducer<any, any>> = [storageSyncReducer];

export const getAppState = createFeatureSelector<State>('user');
export const getUserState =
  createFeatureSelector<fromUserSession.UserState>('user');
export const getAppUserState = createSelector(
  getUserState,
  fromUserSession.getAppUserInfo,
);
