import { Pipe, PipeTransform } from '@angular/core';
import { orderBy } from 'lodash';

// Sort the list of objects based on one parameter
// Syntax - orderBy:<column-name>:asc/dsc
@Pipe({
  name: 'orderBy',
})
export class OrderByPipe implements PipeTransform {
  transform(
    value: any[],
    column: string = '',
    order: boolean | 'asc' | 'desc',
  ): any[] {
    if (!value || !order) {
      return value;
    }
    if (value.length <= 1) {
      return value;
    }
    if (!column || column === '') {
      if (order === 'asc') {
        return value.sort();
      } else {
        return value.sort().reverse();
      }
    }
    return orderBy(value, [column], [order]);
  }
}
