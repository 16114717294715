import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';

import { CCIAppLayoutComponent } from './cciapp-layout/cciapp-layout.component';
import { HeaderComponent } from './cciapp-layout/header/header.component';
import { SidebarComponent } from './cciapp-layout/sidebar/sidebar.component';

@NgModule({
  declarations: [CCIAppLayoutComponent, HeaderComponent, SidebarComponent],
  imports: [SharedModule],
})
export class LayoutModule {}
