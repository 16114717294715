import { Injectable } from '@angular/core';
import { SnackbarComponent } from '../components/snackbar-component/snackbar.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from '../../../environments/environment';
import * as Bowser from 'bowser';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

@Injectable({
  providedIn: 'root',
})
export class SharedServices {
  browser = Bowser.getParser(window.navigator.userAgent).getBrowser();
  fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  fileExtension = '.xlsx';

  constructor(private snackBar: MatSnackBar) {}

  /*
   * @Input - snackbarMessage - Message to be displayed on snackbar
   * snackbarClass - If you want to have custom class for snackbar.
   * @Output - Opens snackbar at the bottom of the page.
   */
  public openSnackBar(snackbarMessage: string, snackbarClass: string): any {
    this.snackBar.openFromComponent(SnackbarComponent, {
      data: snackbarMessage,
      panelClass: snackbarClass,
      duration: environment.snackbarDuration,
    });
  }

  public closeSanckBar(): void {
    this.snackBar.dismiss();
  }

  public getAppSessionID(): string {
    return 'Platform:Desktop:' + this.getBrowserNameAndVersion();
  }

  public getBrowserNameAndVersion(): string {
    return this.browser.name + ' ' + this.browser.version;
  }

  public exportExcel(jsonData: any, fileName: string): void {
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(jsonData);
    const wb: XLSX.WorkBook = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(wb, {
      bookType: 'xlsx',
      type: 'array',
    });
    this.saveExcelFile(excelBuffer, fileName);
  }

  public saveExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: this.fileType });
    FileSaver.saveAs(data, fileName + this.fileExtension);
  }
}
