import { ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { MenuItems } from '../../../shared/menu-items/menu-items';
import { Store, select } from '@ngrx/store';
import * as fromRoot from '../../../shared/state/reducers';
import { AdminTypes } from '../../../constants';
@Component({
  selector: 'aa-app-sidebar',
  templateUrl: './sidebar.component.html',
})
export class SidebarComponent implements OnDestroy {
  mobileQuery: MediaQueryList;

  adminType = '';
  private _mobileQueryListener: () => void;

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _media: MediaMatcher,
    public menuItems: MenuItems,
    private _store: Store<fromRoot.State>,
  ) {
    this.mobileQuery = _media.matchMedia('(min-width: 768px)');
    this._mobileQueryListener = () => _changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
    this._store.pipe(select(fromRoot.getAppUserState)).subscribe((data) => {
      this.adminType = data.typeOfAdmin ? data.typeOfAdmin : '';
    });
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  buildState(states: string[]): any {
    let state = '';
    states.forEach((item) => {
      if (item && item.trim()) {
        state += '/' + item.replace(/^\/+|\/+$/g, '');
      }
    });
    return state;
  }

  /*
   * Determines which links needs to be displayed based on the admin login.
   * Display in navigation only if it belongs to all the admins or just targetted to their admin types.
   */

  checkRouteAccess(allowedAdminAccess: string[] | undefined): boolean {
    if (
      (allowedAdminAccess && allowedAdminAccess.indexOf(AdminTypes.ALL) > -1) ||
      (allowedAdminAccess && allowedAdminAccess.indexOf(this.adminType) > -1)
    ) {
      return true;
    } else {
      return false;
    }
  }
}
