// Global function to allow only numbers
export function allowNumbersOnly(e: any): void {
  const code = e.which ? e.which : e.keyCode;
  if (code > 31 && (code < 48 || code > 57)) {
    e.preventDefault();
  }
}

// dynamically adds property to an object on the fly
export function assign<T, U>(target: T, source: U): asserts target is T & U {
  Object.assign(target, source);
}

// string by triming leading and trailing commas
export function stringByTrimingLeadingAndTrailingCommas(str: string): string {
  return str.replace(/(^,)|(,$)/g, '');
}

// string by splitting comma seperated values to an array
export function stringBySplitingCommaBaseValues(str: string): any[] {
  return str.split(',');
}

// string comparison with empty string or null values
export function isNotEmptyString(str: string): boolean {
  return str !== '' ? true : false;
}
