import {
  Component,
  OnDestroy,
  AfterViewInit,
  ChangeDetectorRef,
} from '@angular/core';
import { LoaderService } from '../../services/loader.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'aa-cci-app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent implements OnDestroy, AfterViewInit {
  loading = false;
  loadingSubscription!: Subscription;

  constructor(
    private _loaderService: LoaderService,
    private _cdr: ChangeDetectorRef,
  ) {}
  ngAfterViewInit(): void {
    // Based on the subscription value show or hide loading div.
    this.loadingSubscription = this._loaderService.isLoading.subscribe(
      (value) => {
        this.loading = value;
        this._cdr.detectChanges();
      },
    );
  }

  ngOnDestroy(): void {
    this.loadingSubscription.unsubscribe();
  }
}
