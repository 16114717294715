import {
  Component,
  Output,
  EventEmitter,
  Input,
  ChangeDetectionStrategy,
} from '@angular/core';
import { Router } from '@angular/router';
import * as UserSessionActions from '../../../shared/state/actions/user-session.actions';
import { Store, select } from '@ngrx/store';
import * as fromRoot from '../../../shared/state/reducers';
import { User } from '../../../models/user';
import { businessUnitsMap } from '../../../constants';
import * as _ from 'lodash';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'aa-cci-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class HeaderComponent {
  @Input() showToggle = true;
  @Input() showBranding = true;

  @Output() toggleSidenav = new EventEmitter<void>();
  @Output() toggleSidenavNotice = new EventEmitter<void>();
  defaultBU = '';
  loggedinUser!: User;
  userBusinessUnits: Array<string> = [];
  businessUnitsMap = businessUnitsMap;

  constructor(private _router: Router, private _store: Store<fromRoot.State>) {
    this._store.pipe(select(fromRoot.getAppUserState)).subscribe((data) => {
      this.loggedinUser = data;
      this.defaultBU = data.businessUnit ? data.businessUnit : '';
      this.userBusinessUnits = data.otherBU ? data.otherBU.split(',') : [];
      this.userBusinessUnits.push(this.defaultBU);
      this.userBusinessUnits = _.sortBy(this.userBusinessUnits);
    });
  }

  logout(): void {
    sessionStorage.clear();
    this._store.dispatch(UserSessionActions.logoutAction());
    this._router.navigate(['/logout']);
  }

  onBusinessUnitChange(businessUnit: string): void {
    const userInfo: User = {
      airlineCode: businessUnit.substring(0, 2),
      businessUnit: businessUnit,
      employeeNumber: this.loggedinUser.employeeNumber,
    };
    this._store.dispatch(UserSessionActions.loginAction({ userInfo }));
    this._router.navigate(['/admin']);
  }

  openCCIWebApp(): void {
    window.open(environment.crewWebAppURL, '_self');
  }
}
