<mat-chip (click)="select.open()" class="filter-label" id="{{ placeholder }}"
  >{{ placeholder }}
  <mat-icon matPrefix>arrow_drop_down</mat-icon>
</mat-chip>
<mat-form-field
  class="custom-dropdown-input"
  style="visibility: hidden; width: 0"
  disableOptionCentering="true">
  <mat-select
    #select
    [placeholder]="placeholder"
    panelClass="filter-select-panel"
    multiple
    appearance="outline">
    <div cdkDropList (cdkDropListDropped)="drop($event)" class="wrapper">
      <mat-card>
        <mat-card-header>
          <mat-card-title> {{ placeholder }}</mat-card-title>
          <span fxFlex></span>
          <mat-icon
            matSuffix
            (click)="select.close()"
            class="button-type"
            id="filter-options-close-button"
            >arrow_drop_up</mat-icon
          >
        </mat-card-header>
        <mat-divider></mat-divider>
        <mat-card-content>
          <div class="search-bar">
            <mat-form-field appearance="outline">
              <mat-icon matPrefix>search</mat-icon>
              <input
                matInput
                type="text"
                placeholder="Search"
                [(ngModel)]="searchStr"
                id="filter-options-search-bar" />
            </mat-form-field>
          </div>
          <mat-divider></mat-divider>
          <div class="footer-content">
            <span>Select all</span>
            <mat-slide-toggle
              [checked]="selectedAll"
              (change)="onSelectAll(selectedAll)"
              id="filter-options-select-all-button"></mat-slide-toggle>
          </div>
          <mat-divider></mat-divider>
          <div class="fix-body">
            <mat-chip-list>
              <mat-chip
                [ngClass]="{ selected: selectedValue.includes(option) }"
                *ngFor="let option of dropDownOptions | filter: searchStr"
                (click)="addDataToList(option)"
                id="{{ option }}">
                {{ option }}</mat-chip
              >
            </mat-chip-list>
          </div>
          <mat-divider></mat-divider>
          <div class="fix-footer footer-content">
            <div>
              <button
                mat-button
                color="primary"
                (click)="onSelectAll(true)"
                id="filter-options-reset-button">
                Reset
              </button>
            </div>
            <div>
              <button
                mat-raised-button
                (click)="select.close()"
                color="primary"
                id="filter-options-done-button">
                Done
              </button>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
      <span cdkDrag>
        <mat-option style="display: none"> </mat-option>
      </span>
    </div>
  </mat-select>
</mat-form-field>
