import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { timeout } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  get options() {
    return {
      headers: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Authorization: `Bearer ${sessionStorage.getItem('pingFederateToken')}`,
      },
    };
  }

  constructor(private httpClient: HttpClient) {}

  postService(url: string, req: any): Observable<any> {
    return this.httpClient
      .post<any>(url, req, this.options)
      .pipe(timeout(environment.apiTimeOut));
  }

  getService(url: string): Observable<any> {
    return this.httpClient
      .get(url, this.options)
      .pipe(timeout(environment.apiTimeOut));
  }

  downloadFileService(url: string): Observable<any> {
    return this.httpClient
      .get(url, {
        responseType: 'arraybuffer',
        ...this.options,
      })
      .pipe(timeout(environment.apiTimeOut));
  }
}
