import { Action, createReducer, on } from '@ngrx/store';
import * as UserSessionActions from '../actions/user-session.actions';
import { User } from '../../../models/user';
import { BusinessUnitConfiguration } from '../../../models/bu-configurations';

export interface UserState {
  user: User;
  loggedIn: boolean;
  businessUnitConfig: BusinessUnitConfiguration;
}

export const initializeState = (): UserState => {
  return {
    user: {
      employeeNumber: 0,
      airlineCode: '',
      isAdmin: false,
      businessUnit: '',
      emulateEmployeeNumber: 0,
      typeOfAdmin: '',
    },
    loggedIn: false,
    businessUnitConfig: {
      messagesTab: [],
      readAndSignEnabled: false,
    },
  };
};

const initialState = initializeState();

const reducer = createReducer(
  initialState,
  on(UserSessionActions.loginAction, (state: UserState, { userInfo }) => {
    return {
      ...state,
      user: { ...state.user, user: userInfo },
      loggedIn: true,
    };
  }),

  on(
    UserSessionActions.loginSuccessAction,
    (state: UserState, { userInfo, businessUnitConfig }) => {
      return {
        ...state,
        user: userInfo,
        loggedIn: true,
        businessUnitConfig: businessUnitConfig,
      };
    },
  ),
  on(UserSessionActions.loginFailureAction, (state: UserState) => {
    return {
      ...state,
      user: { employeeNumber: -1, airlineCode: '' },
      loggedIn: false,
    };
  }),
  on(UserSessionActions.logoutAction, (state: UserState) => {
    return {
      ...state,
      user: { employeeNumber: -1, airlineCode: '' },
      loggedIn: false,
    };
  }),
);

export function userStateReducer(
  state: UserState | undefined,
  action: Action,
): UserState {
  return reducer(state, action);
}

export const getAppState = (state: UserState) => state.user;
export const getAppUserInfo = (state: UserState) => state.user;
