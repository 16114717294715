import { Pipe, PipeTransform } from '@angular/core';

// Filter list of object based on selected parameter
// Syntax - filterByParams:<text-to-filter>:[<selected-column-list>]
@Pipe({
  name: 'filterByParams',
  pure: false,
})
export class FilterByParamsPipe implements PipeTransform {
  transform(list: any[], filter: string, params: string[]): any {
    if (!list || !filter || !params) {
      return list;
    }
    const result: any = [];
    list.filter((item) => {
      params.forEach((param) => {
        if (
          item[param]
            .toLocaleLowerCase()
            .indexOf(filter.toLocaleLowerCase()) !== -1
        ) {
          result.push(item);
        }
      });
    });
    return result;
  }
}
