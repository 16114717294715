/**
 * Created by a294575 on 6/10/20.
 */
export const checklistDropDownData: any = [
  { value: 'Aircraft' },
  { value: 'Crew' },
  { value: 'Flight' },
  { value: 'Input Required' },
  { value: 'Passenger' },
  { value: 'Weather' },
  { value: 'CKA' },
  { value: 'CKA Input Required' },
];

// MARK: - Manage screen
// Drop down System
export const systemDropDownData: any = [{ value: 'FOS' }, { value: 'RES' }];

export const printObject = {
  print: 'Print',
  printAll: 'Print all',
  printLayover: 'Print layover',
};

// Drop down Print
export const printDropDownData: any = [
  { value: 'Print' },
  { value: 'Print all' },
  { value: 'Print layover' },
];

export const checklistColumns: any = [
  {
    columnDef: 'commandDescription',
    header: 'Desc',
    fieldType: 'java.lang.String',
  },
  {
    columnDef: 'commandKeyword',
    header: 'Keyword',
    fieldType: 'java.lang.String',
  },
  {
    columnDef: 'commandTypeCode',
    header: 'Type',
    fieldType: 'java.lang.String',
  },
  {
    columnDef: 'commandKeywordDisplay',
    header: 'Keyword Display',
    fieldType: 'java.lang.String',
  },
  {
    columnDef: 'commandDisplayOrder',
    header: 'Display Order',
    fieldType: 'int',
  },
  { columnDef: 'systemName', header: 'System', fieldType: 'java.lang.String' },
  {
    columnDef: 'activeIndicator',
    header: 'Active ?',
    fieldType: 'java.lang.Boolean',
  },
  {
    columnDef: 'viewIndicator',
    header: 'View ?',
    fieldType: 'java.lang.Boolean',
  },
  {
    columnDef: 'printIndicator',
    header: 'Print ?',
    fieldType: 'java.lang.Boolean',
  },
  {
    columnDef: 'printAllLegsIndicator',
    header: 'Print All?',
    fieldType: 'java.lang.Boolean',
  },
  {
    columnDef: 'printLayoverLegsIndicator',
    header: 'Print Lay Over ?',
    fieldType: 'java.lang.Boolean',
  },
  {
    columnDef: 'updatedDateTime',
    header: 'Updated Date',
    fieldType: 'java.sql.Timestamp',
  },
  {
    columnDef: 'updateEmployeeNumber',
    header: 'Updated EmpID',
    fieldType: 'java.lang.Integer',
  },
];

export const checklistDisplayedColumns = [
  'commandDescription',
  'commandTypeCode',
  'commandKeyword',
  'commandKeywordDisplay',
  'commandDisplayOrder',
  'systemName',
  'activeIndicator',
  'viewIndicator',
  'printIndicator',
  'printAllLegsIndicator',
  'printLayoverLegsIndicator',
  'updatedDateTime',
  'updateEmployeeNumber',
];

export const checklistOperation = {
  addChecklist: 'Add a checklistitem',
  editChecklist: 'Edit a checklistitem',
  dialogWidth: '60%',
  diaglogMinHeight: 'calc(100vh - 80px)',
  dialogHeight: 'auto',
};
