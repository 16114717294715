import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { SharedServices } from '../services/shared.services';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private sharedServices: SharedServices) {}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      retry(3),
      catchError((error: HttpErrorResponse) => {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
          // client-side error
          errorMessage =
            'server returned code :' + error.status + 'with body' + error.error;
        } else {
          if (error.status === 500) {
            errorMessage =
              'server returned code :' +
              error.status +
              '\nMessage:' +
              error.message;
          }
          if (error.status === 400) {
            errorMessage = error.error?.errorMessage;
          }
          if (error.status === 401) {
            errorMessage = error.error;
          } else {
            errorMessage =
              'We are unable to process your request. Please try again.';
          }
        }
        this.sharedServices.openSnackBar(errorMessage, 'error');
        return throwError(errorMessage);
      }),
    );
  }
}
