import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from '../environments/environment';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { RoutesModule } from './routes/routes.module';
import { SharedModule } from './shared/shared.module';
import { LayoutModule } from './layout/layout.module';

import { AppComponent } from './app.component';
import {
  PingAuthenticationModule,
  PingAuthenticationConfig,
} from '@techops-ui/ping-authentication';
import { httpInterceptorProviders } from './shared/http-interceptor/interceptor';

import { UserSessionEffects } from './shared/state/effects/user-session.effects';
import { reducers, metaReducers } from './shared/state/reducers';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { APP_BASE_HREF } from '@angular/common';

// ToDo: Automate this uncomment and URL change process for post logout for different environments
const getPingAuthenticationConfig = () => {
  let config: object = {
    authority: environment.pingAuthAuthority,
    client_id: environment.pingAuthClientId,
    use_html5_routing: true,
    include_sm_session: false,
    scope: 'openid profile',
    idle_threshold: 1800,
    timeout_duration: 1800,
    post_logout_redirect_uri: environment.crewWebAppURL,
    base_href: ''
  }

  return config;
}

export const PING_SERVER_CONFIG: PingAuthenticationConfig = getPingAuthenticationConfig();

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    SharedModule,
    HttpClientModule,
    LayoutModule,
    PingAuthenticationModule.forRoot(PING_SERVER_CONFIG),
    BrowserAnimationsModule,
    StoreModule.forRoot(reducers, { metaReducers }),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
    }),
    EffectsModule.forRoot([UserSessionEffects]),
    RoutesModule,
  ],
  providers: [
    httpInterceptorProviders,
    { provide: APP_BASE_HREF, useValue: '/' },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
