import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'aa-cci-dialog-component',
  templateUrl: './dialog.component.html',
})
export class SharedDialogComponent {
  dialogData: any;

  constructor(
    private dialogRef: MatDialogRef<SharedDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: any,
  ) {
    if (data) {
      this.dialogData = data;
    }
  }
}
