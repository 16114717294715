import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import * as fromRoot from '../shared/state/reducers';
import { Store, select } from '@ngrx/store';
import { User } from '../models/user';
import { AdminTypes } from '../constants';
import { MenuItems } from '../shared/menu-items/menu-items';

@Injectable({
  providedIn: 'root',
})
export class AdminGuard implements CanActivate {
  isLoggedIn = false;
  user!: User;

  constructor(
    private _store: Store<fromRoot.State>,
    private _router: Router,
    private _menuItems: MenuItems,
  ) {
    this._store.pipe(select(fromRoot.getUserState)).subscribe((data) => {
      this.isLoggedIn = data.loggedIn;
      this.user = data.user;
    });
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    if (this.isLoggedIn) {
      if (next.url.length > 0) {
        if (next.url[0].path === 'admin') {
          return true;
        }

        const menuItemsList = this._menuItems.getMenuitem();

        // This should ensure they should not be able to access if the hit URL directly.
        let menuItem: any = null;

        // Loop through menu and submenu and capture menu item once you find a matching element.
        menuItemsList.filter((x) => {
          if (!menuItem) {
            if (x.type === 'link' && x.state.endsWith(next.url[0].path)) {
              menuItem = x;
            } else if (x.submenu) {
              // For all the submenu you will have 2 path one for parent and one for actual config. just pick config one.
              x.submenu.filter((y) => {
                if (y.state.endsWith(next.url[1].path)) {
                  menuItem = y;
                }
              });
            }
          }
        });

        if (menuItem && menuItem.access.length > 0) {
          if (
            menuItem.access.indexOf(AdminTypes.ALL) > -1 ||
            menuItem.access.indexOf(this.user.typeOfAdmin) > -1
          ) {
            return true;
          } else {
            this._router.navigate(['']);
            return true;
          }
        }
      }
    }
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): boolean {
    return this.canActivate(next, state);
  }
}
