import {
  Component,
  ViewChild,
  Input,
  AfterViewInit,
  ChangeDetectorRef,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { configurationStrings } from '../../../routes/admin/configuration/constants';

@Component({
  selector: 'aa-app-table-component',
  templateUrl: './table-component.component.html',
  styleUrls: ['./table-component.component.scss'],
})
export class TableComponentComponent implements AfterViewInit {
  @Input() sortByDefault?: string;
  noRecordstoDisplay: string = configurationStrings.noRecordsDisplay;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort!: MatSort;
  @Input() displayedColumns: any[] = [];
  @Input() columns: any[] = [];
  @Input() tableData: any[] = [];
  @Input() tablePaginator?: boolean;
  public dataSource!: MatTableDataSource<any>;

  constructor(private _cdr: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    this.dataSource = new MatTableDataSource(this.tableData);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.sortByDefault = this.sortByDefault ? this.sortByDefault : '';
    this._cdr.detectChanges();
  }
}
