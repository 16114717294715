export const configurationStrings = {
  configurationUpdateSuccesful:
    'Configuration has been added / updated successfully.',
  configurationOperationUnSuccesful:
    'There was an error in the operation you performed. Please try again!',
  configurationDeleteSuccesful: 'Configuration has been deleted successfully.',
  noRecordsDisplay: 'No Records to Display.',
  noConfigurationsToDisplay:
    'There are no configurations available for your search criteria.',
  paginatorText: 'Rows per page:',
};

export const enum screenConfigurationType {
  BASE = 'BASE',
  FLEET = 'FLEET',
  SEATPOSITION = 'SEATPOSITION',
  NAVIGATION = 'NAVIGATION',
  SCREENBUCONFIG = 'SCREENBUCONFIG',
  BUSINESSUNIT = 'BUSINESSUNIT',
  BUSINESSUNITDATA = 'BUSINESSUNITDATA',
  DATAFIELDCONFIG = 'DATAFIELDCONFIG',
  BASECOORDINATES = 'BASECOORDINATES',
  CODETYPE = 'CODETYPE',
  STATIONCHECKLISTMAPPING = 'STATIONCHECKLISTMAPPING',
  CHECKLIST = 'CHECKLIST',
  COMMANDPARAMETER = 'COMMANDPARAMETER',
  STATIONCHECKLISTITEM = 'STATIONCHECKLISTITEM',
  PRINTERLOCATION = 'PRINTERLOCATION',
  CHECKLISTITEM = 'CHECKLISTITEM',
  USERS = 'USERS',
}

export const enum apiConfigurationType {
  BASE = 'BASE',
  EQUIPMENT = 'EQUIPMENT',
  SEAT = 'SEAT',
  NAVLINK_DATA = 'NAVLINK_DATA',
  SCREEN_BU = 'SCREEN_BU',
  BUSINESS_UNIT = 'BUSINESS_UNIT',
  BUSINESS_UNIT_DATA = 'BUSINESS_UNIT_DATA',
  DATA_FIELD_CONFIG = 'DATA_FIELD_CONFIG',
  BASE_COORDINATES = 'BASE_COORDINATES',
  CODE_TYPE = 'CODE_TYPE',
  STATION_CHECKLIST_MAPPING = 'STATION_CHK_MAP',
  CHECKLIST = 'CHECKLIST',
  SYSTEM_DATA = 'SYSTEM_DATA',
  COMMAND_PARAMETER = 'COMMAND_PARAMETER',
  STATION_CHECKLIST_ITEM = 'STATION_CHECKLIST_ITEM',
  PRINTER_LOCATION = 'PRINTER_LOCATION',
  CHECKLIST_ITEM = 'CHECKLIST_ITEM',
  USERS = 'USERS',
}

export const enum uiDisplay {
  base = 'Base',
  fleet = 'Fleet',
  seatPosition = 'Seat Position',
  navigation = 'Navigation',
  screenBUConfiguration = 'Screen BU Configuration',
  businessUnit = 'Business Unit',
  businessUnitData = 'Business Unit Data',
  dataFieldConfiguration = 'Data Field Configuration',
  baseCoordinates = 'Base Co-ordinates',
  codeTypeData = 'Code Type Data',
  stationCheckListMapping = 'Station Checklist Mapping',
  checkList = 'Checklist',
  commandparameter = 'Command Parameter',
  stationChecklistItem = 'Station Checklist Item',
  printerLocation = 'Printer Location',
  checklistItem = 'Checklist Item',
  users = 'Users',
}

export const statusCodes = {
  success: 'success',
  error: 'error',
};

export const defaultValue = {
  busUnit: 'BUS_UNIT',
};
