import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  OnDestroy,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { ConfigurationService } from '../../../routes/admin/configuration/configuration.service';

@Component({
  selector: 'aa-app-tab-header',
  templateUrl: './tab-header.component.html',
  styleUrls: ['../../styles/tab-header.scss'],
})
export class TabHeaderComponent implements OnInit, OnDestroy {
  @ViewChild('searchBar') searchBar!: ElementRef<any>;

  @Output() emitSearchPopup = new EventEmitter();
  @Output() emitShowFilters = new EventEmitter<boolean>();
  @Output() emitCreateNew = new EventEmitter();
  @Output() emitSearchText = new EventEmitter();
  @Output() emitGlobalFilter = new EventEmitter();

  @Input() title = '';
  @Input() createName = '';
  @Input() showFilter?: boolean;
  @Input() globalFilter?: string;
  @Input() searchPopup?: boolean;
  @Input() hideCreateButton?: boolean;

  // Show or hide filter button
  filterBtn = false;
  globalSearch = '';
  globalSearchSubscription!: Subscription;

  constructor(private _configurationService: ConfigurationService) {}

  ngOnInit(): void {
    this.showFilter === undefined
      ? (this.filterBtn = false)
      : (this.filterBtn = true);
    this.globalSearchSubscription =
      this._configurationService.clearTabHeaderGlobalSearch.subscribe(
        (clearSearch) => {
          if (clearSearch) {
            this.globalSearch = '';
          }
        },
      );
  }

  updateSearchText($event: Event): void {
    this.emitSearchText.emit(($event.target as HTMLInputElement).value);
    this.emitGlobalFilter.emit(this.globalFilter);
  }

  showOrHideFilter(): void {
    this.showFilter = !this.showFilter;
    this.emitShowFilters.emit(this.showFilter);
  }

  createNewObject(): void {
    this.emitCreateNew.emit();
  }

  showOrHideSearchPopup(): void {
    this.emitSearchPopup.emit(
      this.searchBar.nativeElement.getBoundingClientRect(),
    );
  }

  ngOnDestroy(): void {
    this.globalSearchSubscription.unsubscribe();
  }
}
