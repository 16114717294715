import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'aa-cci-app-root',
  template:
    '<aa-cci-app-loader></aa-cci-app-loader><router-outlet></router-outlet>',
})
export class AppComponent {
  title = 'crew check-in';

  isLoggedIn$!: Observable<boolean>;
}
