<div class="header-container">
  <section class="header-item title"
    ><span class="page-title">{{ title }}</span></section
  >
  <section #searchBar class="header-item search-bar">
    <mat-form-field appearance="outline">
      <mat-label>Search</mat-label>
      <mat-icon matPrefix>search</mat-icon>
      <input
        type="text"
        (keyup)="updateSearchText($event)"
        matInput
        [(ngModel)]="globalSearch"
        id="search-textfield" />
      <button
        mat-icon-button
        matSuffix
        (click)="showOrHideSearchPopup()"
        id="arrow-drop-up"
        *ngIf="searchPopup">
        <mat-icon>arrow_drop_up</mat-icon>
      </button>
    </mat-form-field>
  </section>
  <section class="header-item filter" *ngIf="filterBtn">
    <button
      mat-stroked-button
      (click)="showOrHideFilter()"
      id="show-filter-button"
      >{{ showFilter ? 'Hide' : 'Show' }} Filter
      <i class="material-icons mdc-icon-button__icon">filter_list</i>
    </button>
  </section>
  <section class="header-item create-new" *ngIf="!hideCreateButton">
    <button
      mat-stroked-button
      (click)="createNewObject()"
      id="create-new-button"
      ><i class="material-icons mdc-icon-button__icon">add</i> Create a
      {{ createName }}</button
    >
  </section>
</div>
