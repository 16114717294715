/* eslint-disable @typescript-eslint/naming-convention */

import { HttpHeaders } from '@angular/common/http';

export const apiDomain = 'https://services.cci.aa.com/admin';

export const webDomain = 'https://admin.cci.aa.com';

export const environment = {
  production: true,
  apiBaseURL: `${apiDomain}`,
  RequestOptions: {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  },
  crewWebAppURL: `${webDomain}/index.html`,
  snackbarDuration: 10000,
  apiTimeOut: 75000,
  pingAuthAuthority: 'https://idp.aa.com',
  pingAuthClientId: 'CrewChkIn-141609-prod-tWEZF', // CrewChkIn-141609-prod-PCtpG
};

export const reportsURL = {
  probyReport: `${webDomain}/CrewCheckin/ProbyReport.jsp`,
  userActivityReport:
    'https://app.powerbi.com/groups/5faa06c1-831c-47c0-ad1b-81d798f84e61/rdlreports/c28df009-5607-4aaa-8e7f-1d059fb7a5fe?ctid=49793faf-eb3f-4d99-a0cf-aef7cce79dc1&pbi_source=linkShare',
  tripSignInActivityReport:
    'https://app.powerbi.com/groups/5faa06c1-831c-47c0-ad1b-81d798f84e61/rdlreports/62d81068-601b-455d-9db1-36146f6246d0?ctid=49793faf-eb3f-4d99-a0cf-aef7cce79dc1&pbi_source=linkShare',
  mobileTripSignInActivityReport:
    'https://app.powerbi.com/groups/5faa06c1-831c-47c0-ad1b-81d798f84e61/rdlreports/0c294f27-0bd6-405a-b6d8-119d2314dea0?ctid=49793faf-eb3f-4d99-a0cf-aef7cce79dc1&pbi_source=linkShare',
};
