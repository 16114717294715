import { props, createAction } from '@ngrx/store';
import { BusinessUnitConfiguration } from '../../../models/bu-configurations';
import { User } from '../../../models/user';

export const loginAction = createAction(
  '[Auth] PingFederate Login',
  props<{ userInfo: User }>(),
);

export const loginSuccessAction = createAction(
  '[API] Login success',
  props<{ userInfo: User; businessUnitConfig: BusinessUnitConfiguration }>(),
);

export const loginFailureAction = createAction(
  '[API] Login Failure',
  props<{ error: any }>(),
);

export const logoutAction = createAction('[Auth] Logout');

export type UserSessionActionsUnion = ReturnType<
  | typeof loginAction
  | typeof loginSuccessAction
  | typeof loginFailureAction
  | typeof logoutAction
>;
