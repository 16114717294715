<mat-table
  [dataSource]="dataSource"
  matSort
  matSortActive="sortByDefault"
  matSortDirection="asc">
  <ng-container
    [matColumnDef]="column.headerName"
    *ngFor="let column of columns">
    <mat-header-cell *matHeaderCellDef mat-sort-header>
      {{ column.displayName }}
    </mat-header-cell>
    <mat-cell *matCellDef="let row" [attr.data-label]="column.displayName">
      <span>{{ row[column.headerName] }}</span>
    </mat-cell>
  </ng-container>
  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
</mat-table>
<div class="no-records" *ngIf="tableData.length === 0">{{
  noRecordstoDisplay
}}</div>
<mat-paginator
  *ngIf="tablePaginator && tableData.length !== 0"
  #paginator
  [pageIndex]="0"
  [pageSize]="10"
  [pageSizeOptions]="[10, 25, 50, 100]">
</mat-paginator>
