<div class="sidenav_container">
  <div fxLayout="column" fxLayoutGap="10px" style="height: 100%">
    <div>
      <mat-nav-list aaCCIAppAccordion>
        <mat-list-item
          aaCCIAppAccordionLink
          *ngFor="let menuitem of menuItems.getMenuitem()"
          routerLinkActive="selected"
          group="{{ menuitem.state }}"
          id="{{ menuitem.name }}_TAB">
          <a
            class=""
            [routerLink]="buildState([menuitem.state])"
            *ngIf="
              menuitem.type === 'link' && checkRouteAccess(menuitem.access)
            ">
            <i class="material-icons material-icons-outlined">
              {{ menuitem.icon }}
            </i>
            <span>{{ menuitem.name }}</span>
          </a>
          <!--This will create submenu for config items-->
          <a
            [matMenuTriggerFor]="menu"
            class=""
            *ngIf="
              menuitem.type === 'sub' && checkRouteAccess(menuitem.access)
            ">
            <i class="material-icons material-icons-outlined">
              {{ menuitem.icon }}
            </i>
            <span>{{ menuitem.name }}</span>
            <mat-menu
              #menu="matMenu"
              class="mymegamenu"
              [overlapTrigger]="false">
              <ng-container *ngFor="let subMenuItem of menuitem.submenu">
                <ng-container *ngIf="(subMenuItem.type === 'external-link') else link">
                  <a
                    mat-menu-item
                    class=""
                    target="_blank"
                    *ngIf="checkRouteAccess(subMenuItem.access)"
                    [href]="subMenuItem.link"
                    id="{{ subMenuItem.name }}_submenu">
                    <mat-icon>{{ subMenuItem.icon }}</mat-icon>
                    <span>{{ subMenuItem.name }}</span>
                  </a>
                </ng-container>
                <ng-template #link>
                  <a
                    mat-menu-item
                    class=""
                    *ngIf="checkRouteAccess(subMenuItem.access)"
                    [routerLink]="
                      buildState([menuitem.state + '/' + subMenuItem.state])
                    "
                    id="{{ subMenuItem.name }}_submenu">
                    <mat-icon>{{ subMenuItem.icon }}</mat-icon>
                    <span>{{ subMenuItem.name }}</span>
                  </a>
                </ng-template>
              </ng-container>
            </mat-menu>
          </a>
        </mat-list-item>
      </mat-nav-list>
    </div>
    <mat-divider fxHide.gt-xs></mat-divider>
    <span class="spacer"></span>
    <div>
      <mat-nav-list aaCCIAppAccordion>
        <mat-list-item aaCCIAppAccordionLink>
          <a class="">
            <i class="material-icons material-icons-outlined">feedback</i>
            <span>Feedback</span>
          </a>
        </mat-list-item>
      </mat-nav-list>
    </div>
    <div fxHide.gt-xs>
      <aa-cci-header></aa-cci-header>
      <mat-divider></mat-divider>
      <a class="navbar-brand" href="index.html">
        <img
          src="./assets/images/logo-crew-check-in-light.png"
          class="cci-branding-logo-expanded"
          alt="homepage" />
      </a>
    </div>
  </div>
</div>
