import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect, Effect } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import * as UserSessionActions from '../actions/user-session.actions';
import { AuthService } from '../../../routes/auth/auth.service';
import { User } from '../../../models/user';
import { Router } from '@angular/router';
import { AdminTypes } from '../../../constants';
import { BusinessUnitConfiguration } from '../../../models/bu-configurations';

@Injectable()
export class UserSessionEffects {
  @Effect()
  login$ = this._actions$.pipe(
    ofType(UserSessionActions.loginAction),
    mergeMap((action) =>
      this._authService.getUserConfigurationDetails(action.userInfo).pipe(
        map((data) => {
          if (data && data.success) {
            const employeeDetails = data.employee;
            /*
             * Redirect to admin component only if they are admins.
             */
            if (
              employeeDetails != null &&
              employeeDetails.isAdmin &&
              employeeDetails.adminType !== '' &&
              employeeDetails.adminType !== AdminTypes.READONLY
            ) {
              const userInfo: User = {
                airlineCode: data.businessUnit.substring(0, 2),
                employeeNumber: action.userInfo.employeeNumber,
                emulateEmployeeNumber: action.userInfo.emulateEmployeeNumber,
                businessUnit: data.businessUnit,
                firstName: data.firstName,
                lastName: data.lastName,
                isAdmin: employeeDetails.isAdmin,
                typeOfAdmin: employeeDetails.adminType,
                otherBU: employeeDetails.otherBU,
                readAndSignAdminUser: employeeDetails.readAndSignAdminUser,
              };
              const businessUnitConfig: BusinessUnitConfiguration = {
                messagesTab: data.messageTabs,
                readAndSignEnabled: data.readAndSignEnabled
                  ? data.readAndSignEnabled
                  : false,
              };
              return UserSessionActions.loginSuccessAction({
                userInfo,
                businessUnitConfig,
              });
            }
          }
          this._router.navigate(['/sessions/403']);
          return of({});
        }),
        catchError((error) => {
          if (error.error_description.includes('Access token expired')) {
            sessionStorage.clear();
            this._router.navigate(['/logout']);
            return of(UserSessionActions.logoutAction());
          }
          this._router.navigate(['/sessions/500']);
          return of(UserSessionActions.loginFailureAction({ error }));
        }),
      ),
    ),
  );

  loginSuccess$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(UserSessionActions.loginSuccessAction),
        tap(() =>
          this._router.navigate(
            [
              this._router.url &&
              !this._router.url.includes('auth') &&
              this._router.url !== '/'
                ? this._router.url
                : '/admin',
            ],
            { replaceUrl: true },
          ),
        ),
      ),
    { dispatch: false },
  );

  constructor(
    private _actions$: Actions,
    private _authService: AuthService,
    private _router: Router,
  ) {}
}
