<h4 mat-dialog-title>Confirm?</h4>
<mat-dialog-content>{{ data.message }}</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button mat-dialog-close mat-dialog-close="true" id="yes-button">
    Yes
  </button>
  <button mat-button mat-dialog-close mat-dialog-close="false" id="no-button">
    No
  </button>
</mat-dialog-actions>
