import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[aaUppercase]',
})
export class UppercaseDirective {
  @HostListener('input', ['$event']) onKeyUp(event: any): any {
    event.target['value'] = event.target['value'].toUpperCase();
  }
}
