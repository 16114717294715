import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { RouterModule } from '@angular/router';

// Material Module
import { MaterialModule } from '../material.module';

// All Forms Module
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgProgressModule } from '@ngx-progressbar/core';
import { NgProgressRouterModule } from '@ngx-progressbar/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { ToastrModule } from 'ngx-toastr';

// Shared Components goes in here.
import { ErrorCodeComponent } from './components/error-code/error-code.component';
import { LoaderComponent } from './components/loader-component/loader.component';
import { SnackbarComponent } from './components/snackbar-component/snackbar.component';
import { FilterOptionsComponent } from './components/filter-options/filter-options.component';
import { ConfirmComponent } from './components/confirm-component/confirm.component';
import { SharedDialogComponent } from './components/dialog-component/dialog.component';
// Directives
import { UppercaseDirective } from './directives/uppercase.directive';

// Pipes
import { OrderByPipe } from './pipes/orderby.pipe';
import { SearchPipe } from './pipes/search.pipe';
import { FilterByParamsPipe } from './pipes/filterByParams.pipe';

// Accordions
import {
  AccordionDirective,
  AccordionAnchorDirective,
  AccordionLinkDirective,
} from './accordion';
import { MenuItems } from './menu-items/menu-items';
import { CKEditorModule } from 'ng2-ckeditor';
import { TabHeaderComponent } from './components/tab-header/tab-header.component';
import { TableComponentComponent } from './components/table-component/table-component.component';
import {
  NgxMatDatetimePickerModule,
  NgxMatTimepickerModule,
  NgxMatNativeDateModule,
} from '@angular-material-components/datetime-picker';

import { AIOverlay, AISpinner, AILogo } from 'aileron-internal';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const webComponents = [AIOverlay, AISpinner, AILogo];

const THIRD_MODULES = [
  MaterialModule,
  FlexLayoutModule,
  NgProgressModule,
  CKEditorModule,
  NgProgressRouterModule,
  NgSelectModule,
  FormlyMaterialModule,
  NgxMatTimepickerModule,
  NgxMatDatetimePickerModule,
  NgxMatNativeDateModule,
];
const COMPONENTS = [
  ErrorCodeComponent,
  LoaderComponent,
  SnackbarComponent,
  FilterOptionsComponent,
  TabHeaderComponent,
  SharedDialogComponent,
  TableComponentComponent,
];
const COMPONENTS_DYNAMIC = [
  SnackbarComponent,
  FilterOptionsComponent,
  ConfirmComponent,
  SharedDialogComponent,
];
const DIRECTIVES = [UppercaseDirective];
const PIPES = [OrderByPipe, SearchPipe, FilterByParamsPipe];
const ACCORDIONS = [
  AccordionDirective,
  AccordionAnchorDirective,
  AccordionLinkDirective,
];

@NgModule({
  declarations: [
    ...COMPONENTS,
    ...COMPONENTS_DYNAMIC,
    ...DIRECTIVES,
    ...PIPES,
    ...ACCORDIONS,
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    FormlyModule.forRoot(),
    ToastrModule.forRoot(),
    ...THIRD_MODULES,
  ],
  exports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    FormlyModule,
    ToastrModule,
    ...THIRD_MODULES,
    ...COMPONENTS,
    ...DIRECTIVES,
    ...PIPES,
    ...ACCORDIONS,
  ],
  providers: [DatePipe, MenuItems],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}
