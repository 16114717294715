import {
  Component,
  OnDestroy,
  ChangeDetectorRef,
  ViewChild,
} from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { MatSidenav } from '@angular/material/sidenav';

@Component({
  selector: 'aa-cci-layout',
  templateUrl: './cciapp-layout.component.html',
})
export class CCIAppLayoutComponent implements OnDestroy {
  mobileQuery: MediaQueryList;

  @ViewChild('sidenav') sidenav!: MatSidenav;
  iconText = 'menu';

  private _mobileQueryListener: () => void;

  constructor(changeDetectorRef: ChangeDetectorRef, media: MediaMatcher) {
    this.mobileQuery = media.matchMedia('(min-width: 768px)');
    this._mobileQueryListener = () => {
      changeDetectorRef.detectChanges();
    };
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  sideNavToggle(): void {
    this.sidenav.toggle();
    this.iconText = this.sidenav.opened ? 'close' : 'menu';
  }
}
